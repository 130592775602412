import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import Container from 'typedi'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'

@Component({
  name: 'GtrPrintersListView',
  computed: {
    ...mapState('company', ['printers'])
  }
})
export default class GtrPrintersListView extends Vue {
  data () {
    return {
      search: '',
      loading: false,
      table: {
        headers: [
          { text: 'Machine ID', align: 'start', value: 'machine_id' },
          { text: 'URL', value: 'admin_url' },
          { text: 'Company', value: 'company.name' },
          { text: 'Event', value: 'event.name' },
          { text: 'Software Version', value: 'stats.software_version' },
          { text: 'Online', value: 'last_push' },
          { text: 'Actions', value: 'actions', searchable: false, sortable: false, width: '90px' }
        ],
        items: []
      },
      forms: {
        showNewCompanyForm: false
      },
      companyToDelete: null
    }
  }

  @Watch('printers')
  onPrintersChange (payload: any) {
    if (payload) {
      this.$data.table.items = payload
    }
  }

  async mounted () {
    await this.fetchPrinters()
  }

  lastPingIconColor (pingDateTime: string): string {
    const now = Date.now()
    const ping = (new Date(pingDateTime + 'Z')).getTime()
    const diff = now - ping
    return diff <= 60000 ? 'var(--complete-light)' : 'var(--grey-A5)'
  }

  handleViewPrinter (printer: any) {
    this.$store.commit('company/SET_PRINTER', printer)
    this.$router.push({ name: 'level-one.printer', params: { uuid: printer.uuid } })
  }

  handleShowNewCompanyForm () {
    this.$data.forms.showNewCompanyForm = true
  }

  handleCloseNewCompanyForm () {
    this.$data.forms.showNewCompanyForm = false
  }

  handleDeleteCompany (payload: any) {
    this.$data.companyToDelete = payload
  }

  async fetchPrinters () {
    try {
      this.$data.loading = true
      this.$store.commit('company/SET_PRINTERS', [])
      await this.$store.dispatch('company/fetchPrinters')
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }
}
